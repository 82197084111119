import { api } from "../api";

const getProfile = async () => {
  try {
    const response = await api.get("/shared?profile=1");
    if (response.status === 200) {
      return response.data.profile;
    } else {
      localStorage.removeItem("userData");
      return null;
    }
  } catch (error) {
    throw error;
  }
};

const getReservations = async (id) => {
  try {
    const response = await api.get("/user/list", {
      params: { id },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

const getReservation = async (id) => {
  try {
    const response = await api.get(`/reservation/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const create_payment_profile = async (payload) => {
  try {
    const response = await api.post("/user/payment_profile", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const get_payment_profile = async (id) => {
  try {
    const response = await api.get(`/user/list_payment_profiles/${id}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const delete_payment_profile = async (payment_id) => {
  try {
    const response = await api.delete(`/user/payment_profile/${payment_id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
const delete_account = async (account_id) => {
  try {
    const response = await api.delete(`/delete_account/${account_id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  getProfile,
  getReservations,
  create_payment_profile,
  delete_payment_profile,
  delete_account,
  get_payment_profile,
  getReservation,
};
