import { formatMoney } from "@/helpers/filters";
import { mapActions } from "vuex";
import seloProtecao from "@/assets/selo-protecao.svg";
export default {
  name: "CardResult",

  data: () => ({
    seloProtecao: seloProtecao,
    isloading: false,
  }),

  props: {
    cars: Array,
  },

  filters: {
    formatMoney,
  },

  methods: {
    ...mapActions("reservation", ["reservationData"]),
    reserve(car) {
      this.reservationData(car);
      this.$router.push({ name: "Checkout" });
    },
    formattedCEP(value) {
      if (value) {
        const cep = value;
        return cep.substring(0, 5) + "-" + cep.substring(5);
      } else {
        return "";
      }
    },
  },
};
