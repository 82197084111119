<template>
  <div>
    <v-text-field
      v-model.lazy="valueCep"
      v-bind="$attrs"
      v-on="$listeners"
      class="ma-0 pa-0 rounded-xl"
      label="CEP"
      height="50"
      solo
      clearable
      hide-details="auto"
      :rules="cepRules"
      v-mask="'#####-###'"
    >
      <template v-slot:prepend-inner>
        <slot name="prepend"></slot>
      </template>
    </v-text-field>
    <LoadingLinear :loadingLinear="loadingLinear" v-show="false" />
    <ModalReturn :ModalReturn="ModalReturn" v-show="false" />
  </div>
</template>

<script>
import LoadingLinear from "@/components/Modais/LoadingLinear.vue";
import { getCEP } from "@/services/apiCEP";
import ModalReturn from "@/components/Modais/ModalReturn/ModalReturn.vue";
export default {
  name: "text-field-cep",

  components: {
    ModalReturn,
    LoadingLinear,
  },

  data() {
    return {
      loadingLinear: false,
      ModalReturn: {},
      valueCep: this.$attrs.value,
      cepRules: [
        (v) => !!v || "CEP é obrigatório",
        (v) => /^\d{5}-\d{3}|\d{8}$/.test(v) || "CEP (XXXXX-XXX)",
      ],
    };
  },

  watch: {
    "$attrs.value": {
      handler(value) {
        if (!value) {
          this.valueCep = "";
          this.$emit("custom-blur", this.valueCep);
          return;
        }
        this.fetchViaCep(value);
      },
      immediate: true,
    },
  },

  methods: {
    async fetchViaCep(cep) {
      if (!cep) return false;
      const numericValue = cep.replace(/\D/g, "");
      if (numericValue.length === 8) {
        try {
          this.loadingLinear = true;
          const cepAddress = await getCEP(numericValue);
          if (cepAddress.erro) {
            this.ModalReturn = {
              showModal: true,
              status: "error",
              title: "CEP não encontrado",
            };
            this.valueCep = "";
            this.$emit("custom-blur", this.valueCep);
          } else {
            this.valueCep = cep;
            this.$emit("custom-blur", cepAddress);
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.loadingLinear = false;
        }
      }
    },
  },
};
</script>
