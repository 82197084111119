import moment from "moment/moment";
import "moment/locale/pt-br";

const formatMoney = (value) => {
  if (!value) return "R$ 0,00";
  if (isNaN(value)) value = +value.replace(/[^\d.]/g, "");
  const formattedValue = Number(value).toFixed(2);
  const formattedMoney = formattedValue.replace(".", ",");
  return "R$ " + formattedMoney;
};

const formatCPF = (value) => {
  if (value) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else {
    return value;
  }
};

const formatCNPJ = (value) => {
  if (value) {
    return value.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  } else {
    return value;
  }
};

function validarCNH(cnh) {
  if (cnh) {
    const cnhNumerica = cnh.replace(/\D/g, "");

    // Verificar se a CNH tem 11 caracteres numéricos
    if (cnhNumerica.length !== 11) {
      return false;
    }

    // Verificar se todos os caracteres são iguais (CNH inválida nesse caso)
    if (/^(\d)\1*$/.test(cnhNumerica)) {
      return false;
    }

    // Verificar dígitos verificadores
    const multiplicadores1 = [9, 8, 7, 6, 5, 4, 3, 2, 1];
    const multiplicadores2 = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    const calcularDigito = (multiplicadores) => {
      const soma = cnhNumerica
        .slice(0, 9)
        .split("")
        .reduce(
          (acc, digit, index) => acc + parseInt(digit) * multiplicadores[index],
          0
        );

      const resto = soma % 11;

      return resto > 9 ? 0 : resto;
    };

    const digitoVerificador1 = calcularDigito(multiplicadores1);
    const digitoVerificador2 = calcularDigito(multiplicadores2);

    return (
      digitoVerificador1 === parseInt(cnhNumerica.charAt(9)) &&
      digitoVerificador2 === parseInt(cnhNumerica.charAt(10))
    );
  } else {
    return true;
  }
}

const formatPhone = (value) => {
  if (value) {
    const newValue = value.replace(/\s/g, "");
    if (newValue.length === 11) {
      if (newValue.startsWith("0800")) {
        return newValue.replace(/(\d{4})(\d{3})(\d{4})/, "$1 $2 $3");
      }
      return newValue.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (newValue.length === 10) {
      return newValue.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
    return newValue;
  }
};

const formatDate = (value) => {
  if (!value) {
    return value;
  }

  if (moment(value, "DD/MM/YYYY", true).isValid()) {
    return value;
  }

  if (moment(value, "YYYY-MM-DD", true).isValid()) {
    return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
  }

  if (typeof value === "string") {
    return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
  }

  return "Data inválida";
};

const formatDateTime = (value) => {
  if (value) {
    return moment.utc(value).format("DD/MM/YYYY  HH:mm");
  } else {
    return value;
  }
};

const truncate = (value, length) => {
  if (!value) return "";
  if (value.length > length) {
    return value.substring(0, length) + "...";
  } else {
    return value;
  }
};

const formatCEP = (value) => {
  if (!value) return "";
  return value.replace(/(\d{5})(\d{3})/, "$1-$2");
};

const capitalize = (value) => {
  const words = value.split(" ");
  const capitalizedWords = words.map((word) => {
    return word.length > 3
      ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      : word.toLowerCase();
  });
  const capitalizedSentence = capitalizedWords.join(" ");
  return capitalizedSentence;
};

function normalizeString(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim();
}

function isValidDateRange(dateRange) {
  const [startDate, endDate] = dateRange;
  const dateFormat = "DD/MM/YYYY";

  const isValidStartDate = moment(startDate, dateFormat, true).isValid();
  const isValidEndDate = moment(endDate, dateFormat, true).isValid();

  return (
    isValidStartDate &&
    isValidEndDate &&
    moment(startDate, dateFormat).isSameOrBefore(moment(endDate, dateFormat))
  );
}

function isValidTwoDate(endDate, startDate) {
  const end = moment(endDate, "DD/MM/YYYY", true);
  const start = moment(startDate, "DD/MM/YYYY", true);
  return end.isValid() && start.isValid() && end.isAfter(start);
}

function isValidDate(date) {
  const dateFormat = "DD/MM/YYYY";

  const isValidStartDate = moment(date, dateFormat, true).isValid();
  return isValidStartDate;
}

function isValidDateCurrent(date) {
  const dateFormat = "DD/MM/YYYY";

  const isValidFormat = moment(date, dateFormat, true).isValid();

  if (!isValidFormat) {
    return false;
  }

  const currentDate = moment().format(dateFormat);

  const isAfterOrEqual = moment(currentDate, dateFormat).isSameOrBefore(
    moment(date, dateFormat)
  );

  return isAfterOrEqual;
}

function isValidTime(time) {
  if (!/^\d{2}:\d{2}$/.test(time)) {
    return false;
  }

  // Verificar se a hora é válida com Moment.js
  const parsedTime = moment(time, "HH:mm", true);
  return parsedTime.isValid();
}

const convertFloat = (value) => {
  if (value) {
    if (typeof value === "string") {
      value = value.replace(/[^\d.]/g, "");
      return parseFloat(value);
    } else {
      return parseFloat(value);
    }
  }
};

function validarCPF(cpf) {
  if (cpf) {
    cpf = cpf.replace(/[^\d]/g, "");
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
      return false;
    }

    let soma = 0;
    let resto;

    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;

    return true;
  } else {
    return false;
  }
}

export {
  convertFloat,
  formatMoney,
  formatCPF,
  formatPhone,
  formatCNPJ,
  formatDate,
  formatDateTime,
  truncate,
  capitalize,
  validarCNH,
  normalizeString,
  isValidTime,
  isValidTwoDate,
  isValidDate,
  isValidDateCurrent,
  isValidDateRange,
  validarCPF,
  formatCEP,
};
