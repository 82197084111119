<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import HomeView from "@/views/HomeView.vue";
export default {
  name: "App",

  components: {
    HomeView,
  },
};
</script>

<style lang="scss" src="@/scss/general.scss"></style>
